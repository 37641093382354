<template>
  <div class="sub-item">
    <v-card outlined>
      <v-card-text>
        <div v-for="(field, index_field) in fieldList" :key="index_field">
          <inputText
            v-if="field.name == 'url' && showUrl == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
          />
          <SelectList
            v-if="field.name == 'http_verb' && showVerb == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { inputText, SelectList } from "@/components/fields";

export default {
  components: {
    inputText,
    SelectList
  },
  props: ["index", "index_actions", "rules_list", "fieldList"],

  data() {
    return {
      showUrl: true,
      showVerb: true
    };
  },
  mounted() {
    let showRules = this.rules_list[this.index].actions[this.index_actions]
      .fields;

    showRules.url.show_component
      ? (this.showUrl = true)
      : (this.showUrl = false);
    showRules.http_verb.show_component
      ? (this.showVerb = true)
      : (this.showVerb = false);
  }
};
</script>
<style scoped></style>
