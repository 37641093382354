<template>
  <div>
    <v-select
      :items="getStatus(index)"
      label="Valor"
      item-text="display_name"
      item-value="id"
      dense
      outlined
      v-model="option"
      :rules="[v => !!v || 'Campo obrigatório']"
      @change="setValue"
      :loading="loading"
    >
    </v-select>
  </div>
</template>
<script>
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";

export default {
  props: ["index", "index_condition"],
  data() {
    return {
      loading: true,
      condition_type_values: [],
      value_selected: "",
      option: ""
    };
  },
  async mounted() {
    await this.loadSolicitationTypeStatus().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    this.option = this.rules[this.index].conditions[
      this.index_condition
    ].type_value;
    this.loading = false;
  },

  computed: {
    ...mapState("rules", ["rules", "status"]),
    ...mapGetters("rules", ["getStatus", "getSelectedStatus"]),
  },
  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),
    ...mapActions("rules", ["loadSolicitationTypeStatus"]),

    setValue(option) {
      this.rules[this.index].conditions[
        this.index_condition
      ].type_value = option;
      this.setRules(this.rules);
    }
  },

  watch: {
    rules: {
      deep: true,
      handler() {
        this.option = this.getSelectedStatus(this.index);
      }
    }
  }
};
</script>
