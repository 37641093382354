<template>
  <div class="sub-item">
    <v-card outlined>
      <v-card-text>
        <div v-for="(field, index_field) in fieldList" :key="index_field">
          <inputSwitch
            v-if="field.name == 'custom_header' && showCustomHeader == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
            @changeShowStatus="changeShowStatus($event)"
          />
          <inputTextArea
            v-if="field.name == 'header' && showHeader == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { inputSwitch, inputTextArea } from "@/components/fields";

export default {
  components: {
    inputSwitch,
    inputTextArea
  },
  props: ["index", "index_actions", "rules_list", "fieldList"],

  data() {
    return {
      showCustomHeader: true,
      showHeader: false
    };
  },

  mounted() {
    let showRules = this.rules_list[this.index].actions[this.index_actions]
      .fields;

    showRules.custom_header.show_component
      ? (this.showCustomHeader = true)
      : (this.showCustomHeader = false);
    showRules.header.show_component
      ? (this.showHeader = true)
      : (this.showHeader = false);
  },
  methods: {
    changeShowStatus(param) {
      if (param.component == "custom_header") {
        this.showHeader = param.status;
      }
    }
  }
};
</script>
<style scoped></style>
