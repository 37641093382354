<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          :disabled="loading"
          :loading="loading"
        >
          <div>
            <ConnectionComponent
              v-if="hookLoad"
              :index="index"
              :index_actions="index_actions"
              :rules_list="rules_list"
              :fieldList="fieldList"
            />
            <AuthComponent
              v-if="hookLoad"
              :index="index"
              :index_actions="index_actions"
              :rules_list="rules_list"
              :fieldList="fieldList"
            />
            <HeaderComponent
              v-if="hookLoad"
              :index="index"
              :index_actions="index_actions"
              :rules_list="rules_list"
              :fieldList="fieldList"
            />
            <ContentComponent
              v-if="hookLoad"
              :index="index"
              :index_actions="index_actions"
              :rules_list="rules_list"
              :fieldList="fieldList"
            />
          </div>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ConnectionComponent from "./webhooks/connectionComponent";
import AuthComponent from "./webhooks/authComponent";
import ContentComponent from "./webhooks/contentComponent.vue";
import HeaderComponent from "./webhooks/headerComponent.vue";

export default {
  components: {
    AuthComponent,
    ConnectionComponent,
    ContentComponent,
    HeaderComponent
  },
  props: ["index", "index_actions", "actionTypeSelected", "rules_list"],

  data() {
    return {
      hookLoad: false,
      valid: true,
      loading: true,
      values: [],
      mountedComponents: {},
      fieldList: [],
      webhooks: {
        url: {
          url: "",
          show_component: true
        },

        http_verb: {
          http_verb: "",
          show_component: true
        },

        is_autenticated: {
          is_autenticated: false,
          show_component: true
        },

        auth_type: {
          auth_type: ["user", "pass"],
          show_component: false
        },
        user: {
          user: "",
          show_component: false
        },

        pass: {
          pass: "",
          show_component: false
        },

        api_key: {
          api_key: "",
          show_component: false
        },

        custom_header: {
          custom_header: false,
          show_component: true
        },

        header: {
          header: "",
          show_component: false
        },

        encoding: {
          encoding: [],
          show_component: true
        },

        content: {
          content: false,
          show_component: true
        },

        simpleContent: {
          simpleContent: [],
          show_component: true
        },

        custom: {
          custom: "",
          show_component: false
        }
      },
      testeFields: {
        url: {
          show_component: true,
          name: "url",
          type: "inputText",
          label: "URL",
          validate: ["required"]
        },
        http_verb: {
          show_component: true,
          name: "http_verb",
          type: "selectList",
          label: "Tipo de requisição",
          options: [
            {
              text: "GET",
              value: "get"
            },
            {
              text: "POST",
              value: "post"
            },
            {
              text: "PUT",
              value: "put"
            },
            {
              text: "PATCH",
              value: "patch"
            },
            {
              text: "DELETE",
              value: "delete"
            }
          ],
          validate: ["required"]
        },
        is_autenticated: {
          show_component: true,
          is_autenticated: false,
          name: "is_autenticated",
          type: "inputSwitch",
          label: "Possui atenticação"
        },
        auth_type: {
          auth_type: ["user", "pass"],
          show_component: false,
          name: "auth_type",
          type: "inputRadio",
          label: "Tipo de autenticação",
          options: [
            {
              text: "Usuário e senha",
              value: ["user", "pass"]
            },
            {
              text: "Chave de API",
              value: ["api_key"]
            }
          ]
        },
        pass: {
          show_component: false,
          name: "pass",
          type: "inputPassword",
          label: "Senha"
        },
        user: {
          name: "user",
          type: "inputText",
          label: "Usuário",
          show_component: false
        },
        api_key: {
          show_component: false,
          name: "api_key",
          type: "inputText",
          label: "Chave de API"
        },
        encoding: {
          show_component: true,
          name: "encoding",
          type: "inputRadio",
          label: "Codificação",
          options: [
            {
              text: "JSON",
              value: ["json"]
            },
            {
              text: "XML",
              value: ["xml"]
            },
            {
              text: "X-FORM-URLENCODED",
              value: ["x-form-urlencoded"]
            }
          ]
        },
        custom_header: {
          custom_header: false,
          show_component: true,
          name: "custom_header",
          type: "inputSwitch",
          label: "Cabeçalho personalizado",
          components: ["header"]
        },
        header: {
          header: "",
          show_component: false,
          name: "header",
          type: "inputTextArea",
          label: "Cabeçalho"
        },
        content: {
          content: false,
          show_component: true,
          name: "content",
          type: "inputSwitch",
          label: "Conteúdo Customizado"
        },
        custom: {
          show_component: false,
          name: "custom",
          type: "inputTextArea",
          label: "Conteúdo customizado"
        },
        simpleContent: {
          show_component: true,
          name: "simpleContent",
          type: "tagField",
          label: "Cabeçalho"
        }
      },
      testeWebhooks: {
        url: {
          url: "sasaasasasa",
          show_component: true
        },
        pass: {
          pass: "assasasasasaaassa",
          show_component: true
        },
        user: {
          user: "asasasasasasasa",
          show_component: true
        },
        custom: {
          custom: "sasasasasaassaassaa",
          show_component: false
        },
        header: {
          header: "sasasasasa",
          show_component: false
        },
        api_key: {
          api_key: null,
          show_component: false
        },
        content: {
          content: true,
          show_component: true
        },
        encoding: {
          encoding: ["json"],
          show_component: true
        },
        auth_type: {
          auth_type: ["user", "pass"],
          show_component: true
        },
        http_verb: {
          http_verb: {
            text: "POST",
            value: "post"
          },
          show_component: true
        },
        custom_header: {
          custom_header: true,
          show_component: true
        },
        simpleContent: {
          simpleContent: ["company"],
          show_component: true
        },
        is_autenticated: {
          show_component: true,
          is_autenticated: true
        }
      }
    };
  },

  mounted() {
    if (
      !Object.keys(
        this.rules_list[this.index].actions[this.index_actions].fields
      ).length > 0
    ) {
      this.rules_list[this.index].actions[
        this.index_actions
      ].fields = this.webhooks;
    }

    this.action_type_list.data.find(e => {
      if (e.display_name == "Webhook") {
        this.fieldList = e.fields;
      }
    });
    this.hookLoad = true;
    this.loading = false;
  },

  computed: {
    ...mapState("rules", ["rules", "action_type_list"]),
    ...mapState("solicitationType", ["solicitation_type", "new"])
  },

  methods: {
    seeValue(index_field) {
      for (
        let i = 0;
        i < this.mountedComponents[index_field].components.length;
        i++
      ) {
        let componentName = this.mountedComponents[index_field].components[i];
        if (componentName == "custom") {
          this.rules[this.index].actions[
            this.index_actions
          ].fields.simpleContent = [];
          this.mountedComponents.forEach(e => {
            if (e.name == "simpleContent") {
              e.show_component == false
                ? (e.show_component = true)
                : (e.show_component = false);
            }
          });
        }
        this.mountedComponents.forEach(e => {
          if (e.name == componentName) {
            e.show_component == false
              ? (e.show_component = true)
              : (e.show_component = false);
          }
        });
      }
    }
  }
};
</script>
<style scoped></style>
