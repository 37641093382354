<template>
  <v-sheet
    class="mx-auto mt-2"
    elevation="4"
  >
    <v-col cols="12">
      <div class="col-6" v-if="rules_list.length">
        <span class="subtitle-2 pr-2">Condições do Solicitante</span>
      </div>
      <v-col cols="12" class="d-flex justify-center" v-if="!rules_list.length">
        <v-btn
          @click="addCondition"
          variant="outlined"
          elevation="5"
          small
          color="primary"
        >
          Adicionar Condição do Solicitante
          <v-icon right> mdi-plus-circle-outline </v-icon>
        </v-btn>
      </v-col>
      <v-row
        v-for="(condition, index_condition) in rules_list"
        :key="condition.id"
      >
        <v-col cols="6" class="py-0">
          <ConditionTypeComponentNew
            :index_condition="index_condition"
            :value="condition.type"
            :typeConditions="typeConditions"
            @change="onConditionTypeChange($event, index_condition)"
          />
        </v-col>
        <v-col cols="5" class="py-0">
          <ConditionTypeValueComponentNew
            :index_condition="index_condition"
            :value="condition.type_value"
            :conditionsType="itemsConditions[index_condition] || typeValueConditions[condition.type]"
            @change="onConditionTypeValueChange"
          />
        </v-col>
        <v-col cols="1" class="d-flex justify-end py-0 pa-6">
          <v-btn
            color="error"
            fab
            small
            dark
            title="Remover condição"
            @click="removeCondition(index_condition)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-col cols="12" class="d-flex justify-end" v-if="rules_list.length">
        <v-btn
          @click="addCondition"
          variant="outlined"
          elevation="5"
          small
          color="primary"
        >
          Adicionar Condição
          <v-icon right> mdi-plus-circle-outline </v-icon>
        </v-btn>
      </v-col>
    </v-col>
  </v-sheet>
</template>
<script>
import ConditionTypeComponentNew from "./conditions/ConditionTypeComponentNew.vue";
import ConditionTypeValueComponentNew from "./conditions/ConditionTypeValueComponentNew.vue";
export default {
  components: {
    ConditionTypeValueComponentNew,
    ConditionTypeComponentNew
  },

  props: ["rules_list", "conditionsType", "typeConditions", "typeValueConditions"],

  data() {
    return {
      isLoaded: false,
      itemsConditions: [],
      typeSelected: ""
    };
  },

  methods: {
    addCondition() {
      this.$emit("add", {
        condition: "",
        type: "",
        type_value: ""
      });
      this.itemsConditions.push([]);
    },

    removeCondition(index_condition) {
      this.$emit("remove", index_condition);
      this.itemsConditions.splice(index_condition, 1);
    },

    onConditionTypeChange(event, index_condition) {
      this.typeSelected = event;
      this.itemsConditions.splice(index_condition, 1, this.typeValueConditions[event]);
    },

    onConditionTypeValueChange(event, index_condition) {
      this.$emit("change", event, index_condition, this.typeSelected);
    }
  }
};
</script>
