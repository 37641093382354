<template>
  <div>
    <v-select
      :items="types"
      item-text="text"
      item-value="value"
      label="Quando"
      dense
      outlined
      v-model="valueSelected"
      :rules="[v => !!v || 'Campo obrigatório']"
      @change="setValue"
      :loading="loading"
    ></v-select>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: ["index", "index_condition"],
  data() {
    return {
      loading: true,
      types: [
        {
          value: "status",
          text: "Status"
        },
        {
          value: "solicitation",
          text: "Solicitação"
        }
      ],
      valueSelected: ""
    };
  },
  mounted() {
    this.valueSelected = this.rules[this.index].conditions[
      this.index_condition
    ].type;
    this.loading = false;
  },
  computed: {
    ...mapState("rules", ["rules"])
  },
  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),
    setValue() {
      this.rules[this.index].conditions[this.index_condition].type_value = "";

      this.rules[this.index].conditions[
        this.index_condition
      ].type = this.valueSelected;
      this.setRules(this.rules);
    }
  }
};
</script>
