<template>
  <div>
    <div class="col-12 pt-0 pb-1">
      <span class="caption ma-1">Adicionar e-mail e telefone</span>
      <v-icon color="primary" @click="addOption(index_actions)"
        >mdi-plus-circle-outline</v-icon
      >
    </div>

    <v-row>
      <v-col class="ml-3 py-0" cols="12">
        <div
          class="py-0 action"
          v-for="(phone, index_phone) in sendMessage"
          :key="phone.id"
        >
          <v-row>
            <v-col class="py-0" md="5" cols="10">
              <v-autocomplete
                v-model="sendMessage[index_phone].userId"
                label="Nome"
                outlined
                append-icon
                dense
                :items="userList"
                item-text="nome"
                item-value="id"
                placeholder="Selecione"
                @change="setNumber(index_phone)"
                :rules="message"
              ></v-autocomplete>
            </v-col>
            <v-col class="py-0" md="6" cols="10">
              <v-text-field
                outlined
                dense
                label="Email"
                v-model="sendMessage[index_phone].userEmail"
                @change="updateEmail(index_phone)"
                :disabled="!disabledEmailPhone"
              ></v-text-field>
            </v-col>
            <!-- Foi comentado conforme a task 4036 -->
            <!-- <v-col class="py-0" md="3" cols="10">
              <v-text-field
                outlined
                dense
                label="Whatsapp"
                v-model="sendMessage[index_phone].userNumber"
                @change="updateNumber(index_phone)"
                :disabled="!disabledEmailPhone"
              ></v-text-field>
            </v-col> -->
            <v-col cols="1" align-self="baseline" class="py-0">
              <v-tooltip small left>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    @click="removePhone(index, index_actions, index_phone)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span v-if="!showErrorMessage">Remover e-mail e telefone</span>
                <span v-if="showErrorMessage"
                  >Você deve selecionar ao menos um nome</span
                >
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  props: ["index", "index_actions", "userList"],
  data() {
    return {
      sendMessage: [
        {
          userId: "",
          userEmail: "",
          // userNumber: "",
          id: ""
        }
      ],
      usersEmail: [],
      // usersPhone: [],
      usersId: [],
      message: [],
      disabledEmailPhone: false,
      showErrorMessage: false
    };
  },
  async mounted() {
    if (this.new) {
      this.disabledEmailPhone = true;
      let fields = this.rules[this.index].actions[this.index_actions].fields;
      this.sendMessage.pop();
      for (let i = 0; i < fields.action_email?.length; i++) {
        let uniq = "id" + new Date().getTime() + i;
        let position = {
          userId: fields.action_user[i],
          userEmail: fields.action_email[i],
          // userNumber: fields.send_whatsapp_to[i],
          id: uniq
        };
        this.sendMessage.push(position);
      }
    } else {
      let uniq = "id" + new Date().getTime();
      this.sendMessage[0].id = uniq;
    }
  },
  computed: {
    ...mapState("rules", ["rules"]),
    ...mapState("solicitationType", ["new"])
  },

  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    addOption() {
      this.showErrorMessage = false;
      let uniq = "id" + new Date().getTime();
      this.sendMessage.push({
        id: uniq,
        userId: "",
        userNumber: "",
        userEmail: ""
      });
    },

    setNumber(index_phone) {
      this.disabledEmailPhone = true;

      for (let i = 0; i < this.userList?.length; i++) {
        if (this.userList[i].id == this.sendMessage[index_phone].userId) {
          // this.sendMessage[index_phone].userNumber = this.userList[i].linha;
          this.sendMessage[index_phone].userEmail = this.userList[i].email;
        }
      }
      // this.usersPhone = [];
      this.usersEmail = [];
      this.usersId = [];
      for (let i = 0; i < this.sendMessage?.length; i++) {
        // this.usersPhone.push(this.sendMessage[i].userNumber);
        this.usersEmail.push(this.sendMessage[i].userEmail);
        this.usersId.push(this.sendMessage[i].userId);
      }
      this.rules[this.index].actions[
        this.index_actions
      ].fields.action_user = this.usersId;
      this.rules[this.index].actions[
        this.index_actions
      ].fields.action_email = this.usersEmail;
      // this.rules[this.index].actions[
      //   this.index_actions
      // ].fields.send_whatsapp_to = this.usersPhone;
      this.setRules(this.rules);

      if (
        (this.sendMessage[index_phone].userId == "" ||
          this.sendMessage[index_phone].userId == undefined) &&
        this.sendMessage[index_phone].userEmail != ""
        // ||
        //   this.sendMessage[index_phone].userNumber != ""
      ) {
        return (this.message = [`Nome é obrigatório`]);
      } else if (
        (this.sendMessage[index_phone].userId == "" ||
          this.sendMessage[index_phone].userId == undefined) &&
        this.sendMessage[index_phone].userEmail == ""
        // &&
        // this.sendMessage[index_phone].userNumber == ""
      ) {
        this.disabledEmailPhone = false;
        return (this.message = []);
      }
    },

    updateNumber(index_phone) {
      if (
        (this.sendMessage[index_phone].userId == "" ||
          this.sendMessage[index_phone].userId == undefined) &&
        this.sendMessage[index_phone].userEmail != ""
        // ||
        //   this.sendMessage[index_phone].userNumber != ""
      ) {
        return (this.message = [`Nome é obrigatório`]);
      } else if (
        (this.sendMessage[index_phone].userId == "" ||
          this.sendMessage[index_phone].userId == undefined) &&
        this.sendMessage[index_phone].userEmail == ""
        // &&
        // this.sendMessage[index_phone].userNumber == ""
      ) {
        this.disabledEmailPhone = false;
        return (this.message = []);
      }

      // this.rules[this.index].actions[
      //   this.index_actions
      // ].fields.send_whatsapp_to = this.usersPhone;

      // this.rules[this.index].actions[
      //   this.index_actions
      // ].fields.send_whatsapp_to[index_phone] = this.sendMessage[
      //   index_phone
      // ].userNumber;
      this.setRules(this.rules);
    },

    updateEmail(index_phone) {
      if (
        (this.sendMessage[index_phone].userId == "" ||
          this.sendMessage[index_phone].userId == undefined) &&
        (this.sendMessage[index_phone].userEmail != "" ||
          this.sendMessage[index_phone].userNumber != "")
      ) {
        return (this.message = [`Nome é obrigatório`]);
      } else if (
        (this.sendMessage[index_phone].userId == "" ||
          this.sendMessage[index_phone].userId == undefined) &&
        this.sendMessage[index_phone].userEmail == "" &&
        this.sendMessage[index_phone].userNumber == ""
      ) {
        this.disabledEmailPhone = false;
        return (this.message = []);
      }
      this.rules[this.index].actions[
        this.index_actions
      ].fields.action_email = this.usersEmail;
      this.rules[this.index].actions[this.index_actions].fields.action_email[
        index_phone
      ] = this.sendMessage[index_phone].userEmail;
      this.setRules(this.rules);
    },

    removePhone(index, index_actions, index_phone) {
      if (this.sendMessage.length > 1) {
        let newList = [];
        let updateEmail = [];
        // let updatePhone = [];

        for (let i = 0; i < this.sendMessage.length; i++) {
          if (i != index_phone) {
            newList.push(this.sendMessage[i]);
          }
        }
        this.sendMessage = newList;
        for (let i = 0; i < this.sendMessage.length; i++) {
          updateEmail.push(this.sendMessage[i].users);
          // updatePhone.push(this.sendMessage[i].userNumber);
        }
        this.rules[index].actions[
          index_actions
        ].fields.action_email = updateEmail;
        // this.rules[index].actions[
        //   index_actions
        // ].fields.send_whatsapp_to = updatePhone;
      } else {
        this.showErrorMessage = true;
      }
    }
  }
};
</script>
