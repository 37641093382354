<template>
  <div>
    <v-autocomplete
      :items="conditionsType"
      label="For"
      item-text="text"
      item-value="value"
      dense
      outlined
      :value="value_selected"
      @change="onChange"
      :loading="loading"
      :rules="[v => !!v || 'Campo obrigatório']"
    >
      <template v-slot:selection="data">
        {{ data.item.text }}
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>

export default {
  props: ["index", "index_condition", "conditionsType", "value"],
  data() {
    return {
      loading: true,
      condition_type_values: [],
      value_selected: ""
    };
  },
  async mounted() {
    this.value_selected = this.value;
    this.loading = false;
  },

  methods: {
    onChange(event) {
      this.value_selected = event;
      this.$emit("change", event, this.index_condition);
    }
  }
};
</script>
