<template>
  <div>
    <v-autocomplete
      :items="types"
      item-text="text"
      item-value="value"
      label="Quando"
      dense
      outlined
      :value="valueSelected"
      @change="onChange($event)"
      :loading="loading"
      :rules="[v => !!v || 'Campo obrigatório']"
    >
      <template v-slot:selection="data">
        {{ data.item.text }}
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>

export default {
  props: ["index", "index_condition", "typeConditions", "value"],
  data() {
    return {
      loading: true,
      types: [],
      valueSelected: ""
    };
  },

  mounted() {
    this.types = this.typeConditions;
    this.valueSelected = this.value;
    this.loading = false;
  },

  methods: {
    onChange(event) {
      this.valueSelected = event;
      this.$emit("change", event, this.index);
    }
  }
};
</script>
