<template>
  <div class="sub-item">
    <v-card outlined>
      <v-card-text>
        <div v-for="(field, index_field) in fieldList" :key="index_field">
          <inputRadio
            v-if="field.name == 'encoding' && showEncoding == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
          />
          <inputSwitch
            v-if="field.name == 'content' && showContent == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
            @changeShowStatus="changeShowStatus($event)"
          />
          <tagField
            v-if="field.name == 'simpleContent' && showSimpleContent == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
          />
          <inputTextArea
            v-if="field.name == 'custom' && showCustom == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  inputRadio,
  inputSwitch,
  tagField,
  inputTextArea
} from "@/components/fields";

export default {
  components: {
    inputRadio,
    inputSwitch,
    tagField,
    inputTextArea
  },
  props: ["index", "index_actions", "rules_list", "fieldList"],

  data() {
    return {
      showEncoding: true,
      showContent: true,
      showSimpleContent: true,
      showCustom: false
    };
  },

  mounted() {
    let showRules = this.rules_list[this.index].actions[this.index_actions]
      .fields;

    showRules.encoding.show_component
      ? (this.showEncoding = true)
      : (this.showEncoding = false);
    showRules.content.show_component
      ? (this.showContent = true)
      : (this.showContent = false);
    showRules.simpleContent.show_component
      ? (this.showSimpleContent = true)
      : (this.showSimpleContent = false);
    showRules.custom.show_component
      ? (this.showCustom = true)
      : (this.showCustom = false);
  },
  methods: {
    changeShowStatus(param) {
      if (param.component == "content") {
        this.showCustom = param.status;
        this.showCustom
          ? (this.showSimpleContent = false)
          : (this.showSimpleContent = true);
      }
    }
  }
};
</script>
<style scoped></style>
