<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-combobox
          multiple
          label="E-mails"
          outlined
          append-icon=""
          chips
          deletable-chips
          @change="updateEmail"
          dense
          small-chips
          clearable
          :rules="[validateRules.email]"
          v-model="providersSelecteds"
          autocomplete="off"
          :disabled="loadingAutocomplete"
        >
        </v-combobox>
      </v-col>
    </v-row>
    <!-- Foi comentado conforme a task 4036 -->
    <!-- <v-row>
      <ListPhoneUser
        :index="this.index"
        :index_actions="this.index_actions"
        :userList="this.users_list"
      />
    </v-row> -->
    <v-row>
      <v-col cols="12" class="py-0">
        <EmailApproverComponent
          :index="index"
          :selectedId="selectedId"
          @changeSubject="changeSubject($event)"
          @changeMessage="changeMessage($event)"
          :messageEmail="rules[index].actions[index_actions].fields.action_message"
          :subjectEmail="rules[index].actions[index_actions].fields.action_subject"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import ListPhoneUser from "./ListPhoneUser";
import { mapMutations, mapGetters, mapState, mapActions } from "vuex";
import EmailApproverComponent from "../EmailApproverComponent.vue";

export default {
  components: {
    EmailApproverComponent
    // ListPhoneUser
  },
  props: ["index", "index_actions", "selectedId"],
  data() {
    return {
      loadingCombobox: true,
      loadingAutocomplete: true,
      dialog_provider: false,
      tags: [],
      action_subject: "",
      action_message: "",
      search_tags: [],
      search_provider: "",
      search_error_provider: "",
      search_loading_provider: false,
      providerId: [],
      content: "",
      providersSelecteds: [],
      validateRules: {
        email: val => {
          let validate = true;
          //Separe os email
          let list = [];
          val = val + "";
          if (val) {
            let split = val.split(",");
            //se for mais de 1 ele verificar todos
            if (typeof split == "object" && split.length > 1) {
              list = split;
            } else {
              list = [val];
            }
          }
          let erros = "";
          for (let i in list) {
            let email = list[i];
            let email_trim = email.trim();
            let regex = new RegExp("^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,})+$");
            if (!regex.test(email_trim)) {
              if (email_trim != "undefined") {
                erros += email_trim + " não é um e-mail válido. ";
              }
            }
          }
          if (erros != "") {
            validate = erros;
          }
          return validate;
        }
      }
    };
  },
  async mounted() {
    this.providerId = this.getProvider;
    await this.loadSelectedProvider({
      id: this.providerId
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadTagList({
      id: this.selectedId
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    let rules = this.rules[this.index].actions[this.index_actions].fields;
    this.content = rules.action_message;
    this.action_message = rules.action_message;
    this.action_subject = rules.action_subject;
    this.providersSelecteds = rules.action_email;

    this.tags = this.getTagsFormated;
    this.search_tags = this.getTagsFormated;
    this.loadingCombobox = false;
    this.loadingAutocomplete = false;
  },
  computed: {
    ...mapState("rules", ["tag_list", "rules", "users_list"]),
    ...mapState("provider", ["selected_provider"]),
    ...mapState("solicitationType", ["new"]),
    ...mapGetters("rules", ["getRules", "getTagsFormated"]),
    ...mapGetters("solicitationType", ["getProvider"])
  },
  methods: {
    ...mapActions("rules", ["loadValidators", "loadTagList", "loadUsers"]),
    ...mapMutations({ setRules: "rules/setRules" }),
    ...mapActions("provider", ["loadSelectedProvider"]),

    onEditorChange(quill) {
      this.action_message = quill.html;
      this.content = quill.html;
      this.updateFields();
    },
    searchTagsJson(text) {
      if (text != "") {
        const search = new RegExp("(.+)?" + text + "(.+)?", "i");
        const new_tags = [];
        for (let t in this.tags) {
          const tag = this.tags[t];
          if (search.test(tag)) {
            new_tags.push(tag);
          }
        }
        this.search_tags = new_tags;
      } else {
        this.search_tags = this.tags;
      }
    },
    addTag(tag) {
      let content = "";
      if (this.content) {
        content = this.content;
      }
      this.content = content + "{{" + tag + "}}";
    },

    updateEmail(val) {
      this.action_email = val;
      this.updateFields();
    },

    updateFields() {
      this.rules[this.index].actions[this.index_actions].fields.action_email = this.providersSelecteds;

      this.setRules(this.rules);
    },

    changeSubject(item) {
      this.rules[this.index].actions[this.index_actions].fields.action_subject = item.title;
      this.setRules(this.rules);
    },

    changeMessage(item) {
      this.rules[this.index].actions[this.index_actions].fields.action_message = item.message;
      this.setRules(this.rules);
    }
  }
};
</script>
