<template>
  <div>
    <v-select
      :items="condition_options"
      label="Condição"
      dense
      outlined
      v-model="valueSelected"
      @change="setValue"
      :loading="loading"
      :rules="[v => !!v || 'Campo obrigatório']"
      :disabled="loading"
    ></v-select>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: ["index", "index_condition", "value"],
  data() {
    return {
      loading: true,
      condition_options: [
        {
          value: "in",
          text: "For"
        }
        // {
        //   value: "not_in",
        //   text: "Não for"
        // }
      ],
      valueSelected: ""
    };
  },
  async mounted() {
    this.valueSelected = this.rules[this.index].conditions[
      this.index_condition
    ].condition;
    this.loading = false;
  },
  computed: {
    ...mapState("rules", ["rules"])
  },
  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    setValue() {
      this.rules[this.index].conditions[
        this.index_condition
      ].condition = this.valueSelected;
      this.setRules(this.rules);
    }
  }
};
</script>
