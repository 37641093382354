<template>
  <v-sheet
    class="mx-auto mt-6"
    elevation="4"
  >
    <v-col cols="12">
      <div class="col-6">
        <span class="subtitle-2 pr-2">Validadores</span>
      </div>
      <v-col cols="12" class="py-0">
        <div
          class="action py-0"
          v-for="(validator, index_validator) in validators"
          :key="index_validator"
        >
          <v-row>
            <v-col :md="validators.length > 1 ? 11 : 12" cols="12" class="py-0">
              <v-autocomplete
                v-model="validator.id"
                label="Validador"
                outlined
                dense
                :items="userList"
                item-text="nome"
                item-value="id"
                :rules="[v => !!v || 'Validador obrigatório']"
                placeholder="Selecione um validador"
                @change="setUser(index_validator)"
                class="mr-1"
              ></v-autocomplete>
            </v-col>
            <v-col md="4" cols="8" class="py-0" v-show="false">
              <v-text-field
                outlined
                dense
                label="Whatsapp"
                v-model="validator.whatsapp"
                @change="updateNumber(index_validator)"
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="d-flex justify-end py-0 pa-6" v-if="validators.length > 1">
              <v-btn
                color="error"
                fab
                small
                dark
                title="Remover validador"
                @click="removePhone(index_validator)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          @click="addOption(index)"
          variant="outlined"
          elevation="5"
          small
          color="primary"
        >
          Adicionar Validador
          <v-icon right> mdi-plus-circle-outline </v-icon>
        </v-btn>
      </v-col>
    </v-col>
  </v-sheet>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  //\s?(?:\()[0-9]{2}(?:\))\s
  props: ["index", "userList", "validatorCard"],
  data() {
    return {
      //rulesWhatsapp: [v => /^[0-9]{2}?(?:\()[0-9]{2}(?:\))?[0-9]{4,5}(?:-)[0-9]{4}$/.test(v) || "Insira um valor numerico."],
      validators: []
    };
  },
  async mounted() {
    if (this.solicitationTypeValidatorRules[this.index] && this.solicitationTypeValidatorRules[this.index].validators.length > 0) {
      this.validators = this.solicitationTypeValidatorRules[this.index].validators;
    } else {
      this.addOption();
    }

  },
  computed: {
    ...mapState("rules", ["rules"]),
    ...mapState("approvalRules", ["solicitationTypeValidatorRules"]),
  },

  methods: {
    addOption() {
      this.validators.push({ id: "", whatsapp: "" });
    },

    setUser(index_validator) {
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === this.validators[index_validator].id) {
          this.validators[index_validator].whatsapp = this.userList[i].linha;
        }
      }
      this.validatorCard[this.index].validators = this.validators;
    },

    updateNumber(index_validator) {
      this.validatorCard[this.index].validators[
        index_validator
      ].whatsapp = this.validators[index_validator].whatsapp;
    },

    removePhone(index_validator) {
      let newList = [];
      for (let i = 0; i < this.validators.length; i++) {
        if (i != index_validator) {
          newList.push(this.validators[i]);
        }
      }
      this.validators = newList;
      this.validatorCard[this.index].validators = this.validators;
    }
  }
};
</script>
