<template>
  <v-container>
    <div v-if="loading" class="text-center ma-5">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <v-col md="12" cols="12" class="py-0">
        <div class="text-right mb-3 mt-3">
          <v-spacer></v-spacer>
          <v-btn elevation="1" color="error" @click="removeValidatorRules(index_validator)">
            Excluir Regra!
          </v-btn>
        </div>
      </v-col>
      <v-col md="12" cols="12" class="py-0">
        <v-text-field
          label="Descrição da regra"
          dense
          outlined
          :rules="[v => !!v || 'Descrição obrigatório']"
          v-model="validator_rules_list[index_validator].values.description"
          @change="updateRule"
        ></v-text-field>
      </v-col>

      <v-col md="12" cols="12" class="py-0">
        <ListConditionsComponentNew
          :rules_list="rules_list"
          @add="addNewCondition"
          @remove="removeCondition"
          @change="changeTypeValue"
          :conditionsType="conditionsType"
          :typeConditions="typeConditions"
          :typeValueConditions="typeValueConditions"
        />
      </v-col>

      <v-col cols="12" class="py-0">
        <WhatsappValidator
          :userList="approversList"
          :index="index_validator"
          :validatorCard="validator_rules_list"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import ListConditionsComponentNew from "../solicitationType/ListConditionsComponentNew.vue";
import WhatsappValidator from "../solicitationType/WhatsappValidator.vue";

export default {
  components: {
    WhatsappValidator,
    ListConditionsComponentNew,
  },
  props: ["selectedId", "validator_rules_list", "index_validator"],
  data() {
    return {
      loading: true,
      approval_card: [],
      options: {},
      approversList: [],
      rules_list: [],
      conditionsType: [],
      condition: "",
      typeConditions: [],
      typeValueConditions: {}
    };
  },
  async mounted() {
    try {
      await Promise.all([
        this.loadApprovalOptions(),
        this.loadApprovers(),
        this.loadTagList({id: this.selectedId}),
      ]);

      this.rules_list = this.validator_rules_list[this.index_validator].values.conditions;
      this.options = this.approval_options;
      this.typeConditions = this.options.conditions;
      this.typeValueConditions = this.options;
      this.approversList = this.approvers_options;
      this.loading = false;
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
  },
  computed: {
    ...mapState("approvalRules", [
      "approval_options",
      "approvers_options",
      "approvers",
      "solicitationTypeApprovalRules"
    ])
  },

  methods: {
    ...mapActions("approvalRules", [
      "loadApprovalOptions",
      "loadApprovers",
      "loadApprovalRules"
    ]),
    ...mapActions("rules", ["loadTagList"]),
    ...mapMutations({ setValidatorRules: "approvalRules/setValidatorRules" }),

    changeSubject(item) {
      this.validator_rules_list[item.index_rule].values.action_subject =
        item.title;
      this.updateRule(this.validator_rules_list);
    },

    changeMessage(item) {
      this.validator_rules_list[item.index_rule].values.action_message =
        item.message;
      this.updateRule(this.validator_rules_list);
    },

    removeValidatorRules(index_rule) {
      if (index_rule > -1) {
        this.validator_rules_list.splice(index_rule, 1);
      }
      this.setValidatorRules(this.validator_rules_list);
    },

    updateRule() {
      this.setValidatorRules(this.validator_rules_list);
    },

    addNewCondition(newCondition) {
      this.validator_rules_list[this.index_validator].values.conditions.push(newCondition);
    },

    removeCondition(index) {
      this.rules_list.splice(index, 1);
    },

    changeTypeValue(event, index, typeSelected) {
      this.validator_rules_list[this.index_validator].values.conditions.splice(index, 1, {
        condition: "for",
        type: typeSelected,
        type_value: event
      });
      this.rules_list = this.validator_rules_list[this.index_validator].values.conditions;
      this.setValidatorRules(this.validator_rules_list);
    }
  },
  destroyed() {
    this.validator_rules_list = [];
    this.setValidatorRules(this.validator_rules_list);
  }
};
</script>
