<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <EmailApproverComponent
          :index="index"
          :selectedId="selectedId"
          @changeSubject="changeSubject($event)"
          @changeMessage="changeMessage($event)"
          :messageEmail="
            rules[index].actions[index_actions].fields.action_message
          "
          :subjectEmail="
            rules[index].actions[index_actions].fields.action_subject
          "
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState, mapActions } from "vuex";
import EmailApproverComponent from "../EmailApproverComponent.vue";

export default {
  components: {
    EmailApproverComponent
  },
  props: ["index", "index_actions", "selectedId"],
  data() {
    return {
      tags: [],
      action_subject: "",
      action_message: "",
      search_tags: [],
      content: ""
    };
  },
  async mounted() {
    await this.loadRequesters().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadTagList({
      id: this.selectedId
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    this.tags = this.getTagsFormated;
    this.search_tags = this.getTagsFormated;

    let fields = this.rules[this.index].actions[this.index_actions].fields;
    this.content = fields.action_message;
    this.action_subject = fields.action_subject;
    this.action_message = fields.action_message;
  },
  computed: {
    ...mapState("solicitationType", ["new"]),
    ...mapState("rules", ["rules", "tag_list", "requesters_list"]),
    ...mapGetters("rules", ["getTagsFormated"])
  },
  methods: {
    ...mapActions("rules", ["loadTagList", "loadRequesters"]),
    ...mapMutations({ rulesSetActionFields: "rules/setActionFields" }),
    ...mapMutations({ setRules: "rules/setRules" }),

    onEditorChange(quill) {
      this.action_message = quill.html;
      this.content = quill.html;
    },

    searchTagsJson(text) {
      if (text != "") {
        const search = new RegExp("(.+)?" + text + "(.+)?", "i");
        const new_tags = [];
        for (let t in this.tags) {
          const tag = this.tags[t];
          if (search.test(tag)) {
            new_tags.push(tag);
          }
        }
        this.search_tags = new_tags;
      } else {
        this.search_tags = this.tags;
      }
    },

    addTag(tag) {
      let content = "";
      if (this.content) {
        content = this.content;
      }
      this.content = content + "{{" + tag + "}}";
    },

    changeSubject(item) {
      this.rules[this.index].actions[this.index_actions].fields.action_subject =
        item.title;
      this.setRules(this.rules);
    },

    changeMessage(item) {
      this.rules[this.index].actions[this.index_actions].fields.action_message =
        item.message;
      this.setRules(this.rules);
    }
  }
};
</script>
