<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <EmailApproverComponent
          :index="index"
          :selectedId="selectedId"
          @changeSubject="changeSubject($event)"
          @changeMessage="changeMessage($event)"
          :messageEmail="rules[index].actions[index_actions].fields.action_message"
          :subjectEmail="rules[index].actions[index_actions].fields.action_subject"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ListPhoneUser from "./ListPhoneUser";
import EmailApproverComponent from "../EmailApproverComponent.vue";

export default {
  components: {
    EmailApproverComponent,
  },
  props: ["index", "index_actions", "selectedId"],
  data() {
    return {
      dialogValidator: false,
      search_loading: false,
      search: "",
      selected: {},
      tags: [],
      action_email: "",
      action_subject: "",
      action_message: "",
      search_tags: [],
      content: "",
      list_response: [],
      search_error: "",
      validators: [],
      validatorNumber: ""
    };
  },
  async mounted() {
    await this.loadTagList({
      id: this.selectedId
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.tags = this.getTagsFormated;
    this.search_tags = this.getTagsFormated;

    let fields = this.rules[this.index].actions[this.index_actions].fields;
    this.content = fields.action_message;
    this.action_message = fields.action_message;
    this.action_subject = fields.action_subject;
  },
  computed: {
    ...mapState("rules", ["rules", "tag_list"]),
    ...mapState("solicitationType", ["new"]),
    ...mapGetters("rules", ["getTagsFormated"])
  },

  methods: {
    ...mapActions("rules", ["loadTagList"]),
    ...mapMutations({ setRules: "rules/setRules" }),

    onEditorChange(quill) {
      this.action_message = quill.html;
      this.content = quill.html;
      this.updateFields();
    },

    searchTagsJson(text) {
      if (text != "") {
        const search = new RegExp("(.+)?" + text + "(.+)?", "i");
        const new_tags = [];
        for (let t in this.tags) {
          const tag = this.tags[t];
          if (search.test(tag)) {
            new_tags.push(tag);
          }
        }
        this.search_tags = new_tags;
      } else {
        this.search_tags = this.tags;
      }
    },

    addTag(tag) {
      let content = "";
      if (this.content) {
        content = this.content;
      }
      this.content = content + "{{" + tag + "}}";
    },

    updateSubject(val) {
      this.action_subject = val;
      this.updateFields();
    },

    updateFields() {
      this.rules[this.index].actions[
        this.index_actions
      ].fields.action_email = this.providersSelecteds;
      this.setRules(this.rules);
    },

    changeSubject(item) {
      this.rules[this.index].actions[this.index_actions].fields.action_subject =
        item.title;
      this.setRules(this.rules);
    },

    changeMessage(item) {
      this.rules[this.index].actions[this.index_actions].fields.action_message =
        item.message;
      this.setRules(this.rules);
    }
  }
};
</script>
