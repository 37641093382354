<template>
  <v-row>
    <div class="pb-1 col-6">
      <span class="subtitle-2 pr-2">Condições</span>
<!--      <v-icon color="primary" @click="addCondition(index)"-->
<!--        >mdi-plus-circle-outline</v-icon-->
<!--      >-->
    </div>
    <v-col cols="12" class="py-0">
      <v-row
        v-for="(condition, index_condition) in conditions_card"
        :key="condition.id"
      >
        <v-col md="4" cols="10" class="py-0">
          <ConditionTypeComponent
            :index="index"
            :index_condition="index_condition"
            :value="condition.type"
            v-if="isLoaded"
          />
        </v-col>
        <v-col md="3" cols="10" class="py-0">
          <ConditionOptionComponent
            :index="index"
            :index_condition="index_condition"
            :value="condition.condition"
            v-if="isLoaded"
          />
        </v-col>
        <v-col md="3" cols="10" class="py-0">
          <ConditionTypeValueComponent
            :index="index"
            :index_condition="index_condition"
            :value="condition.type_value"
            v-if="isLoaded"
          />
        </v-col>
<!--        <v-col md="1" cols="1" class="py-1">-->
<!--          <v-tooltip small left>-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <v-icon v-on="on" @click="removeCondition(index_condition)"-->
<!--                >mdi-delete</v-icon-->
<!--              >-->
<!--            </template>-->
<!--            <span>Remover condição</span>-->
<!--          </v-tooltip>-->
<!--        </v-col>-->
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import ConditionTypeComponent from "./conditions/ConditionTypeComponent.vue";
import ConditionOptionComponent from "./conditions/ConditionOptionComponent.vue";
import ConditionTypeValueComponent from "./conditions/ConditionTypeValueComponent.vue";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    ConditionTypeComponent,
    ConditionOptionComponent,
    ConditionTypeValueComponent
  },

  props: ["index", "conditions_list"],

  data() {
    return {
      conditions_card: [],
      options_conditions: [],
      type_values: [],
      isLoaded: false,
    };
  },
  async mounted() {
    this.conditions_card = this.conditions_list;
    this.isLoaded = true;
  },

  computed: {
    ...mapState("solicitationType", ["new"]),
    ...mapState("rules", ["rules", "messageRules"])
  },

  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    addCondition(index) {
      this.rules[index].conditions.push({
        type: "",
        condition: "",
        type_value: ""
      });
      this.setRules(this.rules);
    },

    removeCondition(index_condition) {
      if (index_condition > -1) {
        this.rules[this.index].conditions.splice(index_condition, 1);
      }
      this.setRules(this.rules);
    }
  }
};
</script>
