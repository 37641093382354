<template>
  <v-container>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card :loading="loading || load">
        <v-card-title>
          <span class="title-1">Grupos de Usuarios</span>
          <v-spacer></v-spacer>
          <v-btn color="blue-grey darken-2" text @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card outlined>
          <v-card-text class="subtitle-1">
            <span>Vinculados</span>
            <v-chip-group v-if="!loading" column>
              <v-chip
                :disabled="loading"
                color="primary"
                outlined
                close
                @click:close="disassociateGroup(item)"
                v-for="item in associatedGroupList"
                :key="item.value"
                :value="item.value"
              >
                <v-icon left small>{{ item.icon }}</v-icon>
                {{ item.text }}
              </v-chip>
              <v-alert
                v-if="!loading && !associatedGroupList.length"
                border="left"
                dense
                colored-border
                type="warning"
                >Nenhum grupo vinculado!
              </v-alert>
            </v-chip-group>
            <v-chip-group v-else column>
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </v-chip-group>
          </v-card-text>

          <v-divider class="divider-margin dark"></v-divider>

          <v-card-text class="subtitle-1">
            <span>Disponíveis</span>
            <v-chip-group v-if="!loading" column>
              <v-chip
                @click="associate(item)"
                v-for="item in availableGroups"
                :key="item.value"
                :value="item.value"
              >
                <v-icon left small>{{ item.icon }}</v-icon>
                {{ item.text }}
              </v-chip>
              <v-alert
                v-if="!loading && !availableGroups.length"
                border="left"
                dense
                colored-border
                type="warning"
                >Nenhum grupo encontrado!
              </v-alert>
            </v-chip-group>
            <v-chip-group v-else column>
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </v-chip-group>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-container class="d-flex justify-end">
            <v-btn
                color="darken-2"
                text
                class="primary"
                @click="saveData()"
                v-if="!disableSave"
                :loading="saveLoading"
            >
              Salvar
            </v-btn>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["selectedId"],
  data() {
    return {
      dialog: true,
      loading: true,
      load: false,
      disableSave: true,
      associatedGroupList: [],
      availableGroups: [],
      saveLoading: false,
    };
  },
  async mounted() {
    await Promise.all([
      this.loadUserGroup(),
      this.loadUserGroupAssociated(this.selectedId)
    ]).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (this.associatedUserGroups.data.user_groups?.length) {
      this.associatedGroupList = this.associatedUserGroups.data.user_groups;
    }

    this.generateList(this.user_groups);

    this.loading = false;
  },

  computed: {
    ...mapState("solicitationType", [
      "user_groups",
      "userGroupsAssociate",
      "associatedUserGroups"
    ])
  },

  methods: {
    ...mapActions("solicitationType", [
      "loadUserGroup",
      "loadUserGroupAssociated",
      "associateUserGroups"
    ]),

    generateList(data) {
      let groupList = [];
      data.map(groupData => {
        let group = {
          value: groupData.id_join,
          text: groupData.text
        };
        groupList.push(group);
      });
      this.availableGroups = groupList;

      this.associatedGroupList.map(associated => {
        this.availableGroups.map((toRemove, index) => {
          if (toRemove.text === associated.text) {
            this.availableGroups.splice(index, 1);
          }
        });
      });
    },

    associate(group) {
      let removeItem = [];
      this.associatedGroupList.push({
        value: group.value,
        text: group.text
      });

      this.availableGroups.map(groupData => {
        if (groupData.value != group.value) {
          removeItem.push(groupData);
        }
      });
      this.availableGroups = removeItem;
      this.disableSave = false;
    },

    disassociateGroup(group) {
      let removeItem = [];

      this.associatedGroupList.map(groupItem => {
        if (groupItem.value != group.value) {
          removeItem.push(groupItem);
        }
      });

      this.availableGroups.push(group);

      this.associatedGroupList = removeItem;
      this.disableSave = false;
    },

    closeDialog() {
      this.$emit("closeDialog");
    },

    saveData() {
      this.saveAssociation();
    },

    async saveAssociation() {
      this.saveLoading = true;
      let associateGroup = [];

      this.associatedGroupList.map(group => {
        associateGroup.push(group.value);
      });
      let params = {
        solicitationType_id: this.selectedId,
        user_groups: associateGroup
      };
      await this.associateUserGroups(params).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });

      if (this.userGroupsAssociate.success) {
        await this.$swal({
          icon: "success",
          title: this.userGroupsAssociate.message,
          confirmButtonText: "Ok",
          confirmButtonColor: "#3085d6",
          showLoaderOnConfirm: true
        });

        this.$emit("closeDialog");
      } else {
        await this.$swal("Oops ...", this.userGroupsAssociate.message, "warning");
      }
      this.saveLoading = false;
    }
  }
};
</script>

<style scoped>
.removeOpacity:focus::before {
  opacity: 0 !important;
}
</style>
