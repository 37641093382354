<template>
  <div class="sub-item">
    <v-card outlined>
      <v-card-text>
        <div v-for="(field, index_field) in fieldList" :key="index_field">
          <inputSwitch
            v-if="field.name == 'is_autenticated' && showAutenticated == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
            @changeShowStatus="changeShowStatus($event)"
          />
          <inputRadio
            v-if="field.name == 'auth_type' && showAuthType == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
            @changeShowStatus="changeShowStatus($event)"
          />
          <inputText
            v-if="field.name == 'user' && showUser == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
          />
          <inputPassword
            v-if="field.name == 'pass' && showPass == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
          />
          <inputText
            v-if="field.name == 'api_key' && showApiKey == true"
            :params="field"
            :index="index"
            :index_actions="index_actions"
            :rules_list="rules_list"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import {
  inputText,
  inputRadio,
  inputPassword,
  inputSwitch
} from "@/components/fields";

export default {
  components: {
    inputSwitch,
    inputRadio,
    inputText,
    inputPassword
  },
  props: ["index", "index_actions", "rules_list", "fieldList"],

  data() {
    return {
      showAutenticated: true,
      showAuthType: false,
      showUser: false,
      showPass: false,
      showApiKey: false
    };
  },
  mounted() {
    let showRules = this.rules_list[this.index].actions[this.index_actions]
      .fields;

    showRules.is_autenticated.show_component
      ? (this.showAutenticated = true)
      : (this.showAutenticated = false);
    showRules.auth_type.show_component
      ? (this.showAuthType = true)
      : (this.showAuthType = false);
    showRules.user.show_component
      ? (this.showUser = true)
      : (this.showUser = false);
    showRules.pass.show_component
      ? (this.showPass = true)
      : (this.showPass = false);
    showRules.api_key.show_component
      ? (this.showApiKey = true)
      : (this.showApiKey = false);
  },
  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    changeShowStatus(param) {
      let showRules = this.rules_list[this.index].actions[this.index_actions]
        .fields;

      if (param.component == "is_autenticated") {
        this.showAuthType = param.status;
        if (this.showAuthType == true) {
          showRules.auth_type.show_component = true;
        } else {
          showRules.auth_type.show_component = false;
          showRules.auth_type.auth_type = "";

          this.showUser = false;
          this.showPass = false;
          this.showApiKey = false;

          showRules.user.show_component = false;
          showRules.pass.show_component = false;
          showRules.api_key.show_component = false;

          showRules.user.user = "";
          showRules.pass.pass = "";
          showRules.api_key.api_key = "";
        }
      }
      if (param.component == "auth_type") {
        if (param.status[0] == "user") {
          this.showUser = true;
          this.showPass = true;

          showRules.user.show_component = true;
          showRules.pass.show_component = true;
        } else {
          this.showUser = false;
          this.showPass = false;
          showRules.user.show_component = false;
          showRules.pass.show_component = false;
          showRules.user.user = "";
          showRules.pass.pass = "";
        }
        if (param.status[0] == "api_key") {
          this.showApiKey = true;
          showRules.api_key.show_component = true;
        } else {
          this.showApiKey = false;
          showRules.api_key.show_component = false;
          showRules.api_key.api_key = "";
        }
        this.rules_list[this.index].actions[
          this.index_actions
        ].fields = showRules;
        this.setRules(this.rules_list);
      }
    }
  }
};
</script>
<style scoped></style>
