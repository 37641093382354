<template>
  <v-container>
    <div v-if="loading" class="text-center ma-5">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-row v-if="!loading">
      <v-col md="12" cols="12" class="py-0">
        <div class="text-right mb-3 mt-3">
          <v-spacer></v-spacer>
          <v-btn elevation="1" color="error" @click="removeApprovalRules(index_approval)">
            Excluir Regra!
          </v-btn>
        </div>
      </v-col>
      <v-col md="12" cols="12" class="py-0">
        <v-text-field
          label="Descrição da regra"
          dense
          :rules="[v => !!v || 'Descrição obrigatório']"
          outlined
          v-model="approval_rules_list[index_approval].values.description"
          @change="updateRule"
        ></v-text-field>
      </v-col>

      <v-col md="12" cols="12" class="py-0">
        <ListConditionsComponentNew
          :rules_list="rules_list"
          @add="addNewCondition"
          @remove="removeCondition"
          @change="changeTypeValue"
          :conditionsType="conditionsType"
          :typeConditions="typeConditions"
          :typeValueConditions="typeValueConditions"
        />
      </v-col>

      <v-col cols="12">
        <v-sheet
          class="mx-auto mt-8"
          elevation="4"
        >
          <v-col md="12" cols="12" class="py-0">
            <span class="subtitle-2 pr-2">Configurações para Aprovação</span>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-select
                  label="Tipo de Aprovação"
                  dense
                  outlined
                  :rules="[v => !!v || 'Campo obrigatório']"
                  :items="options.types"
                  v-model="approval_rules_list[index_approval].values.qt_approvers"
                  @change="updateRule"
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-select
                  label="Limite para Aprovação"
                  dense
                  outlined
                  :rules="[v => !!v || 'Campo obrigatório']"
                  :items="options.limit_approval"
                  v-model="approval_rules_list[index_approval].values.approval_limit"
                  @change="updateRule"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="py-0">
            <WhatsappApprover
              :userList="approversList"
              :index="index_approval"
              :approvalCard="approval_rules_list"
            />
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import WhatsappApprover from "../solicitationType/WhatsappApprover";
import EmailApproverComponent from "../solicitationType/EmailApproverComponent";
import ListConditionsComponentNew from "../solicitationType/ListConditionsComponentNew.vue";

export default {
  components: {
    ListConditionsComponentNew,
    WhatsappApprover
  },
  props: ["selectedId", "approval_rules_list", "index_approval"],
  data() {
    return {
      approval_card: [],
      options: {},
      approversList: [],
      rules_list: [],
      loading: true,
      conditionsType: [],
      condition: "",
      typeConditions: [],
      typeValueConditions: {}
    };
  },
  async mounted() {
    try {
      await Promise.all([
        this.loadApprovalOptions(),
        this.loadApprovers(),
        this.loadTagList({id: this.selectedId}),
      ]);

      this.rules_list = this.approval_rules_list[this.index_approval].values.conditions;
      this.options = this.approval_options;
      this.typeConditions = this.options.conditions;
      this.typeValueConditions = this.options;
      this.approversList = this.approvers_options;
      this.loading = false;
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
  },
  computed: {
    ...mapState("approvalRules", [
      "approval_options",
      "approvers_options",
      "approvers",
      "solicitationTypeApprovalRules"
    ])
  },

  methods: {
    ...mapActions("approvalRules", [
      "loadApprovalOptions",
      "loadApprovers",
      "loadApprovalRules"
    ]),
    ...mapActions("rules", ["loadTagList"]),
    ...mapMutations({ setApprovalRules: "approvalRules/setApprovalRules" }),
    ...mapMutations({ setApprovers: "approvalRules/setApprovers" }),

    changeSubject(item) {
      this.approval_rules_list[item.index_rule].values.action_subject =
        item.title;
      this.updateRule(this.approval_rules_list);
    },

    changeMessage(item) {
      this.approval_rules_list[item.index_rule].values.action_message =
        item.message;
      this.updateRule(this.approval_rules_list);
    },

    updateRule() {
      this.setApprovalRules(this.approval_rules_list);
    },

    removeApprovalRules(index_rule) {
      if (index_rule > -1) {
        this.approval_rules_list.splice(index_rule, 1);
      }
      this.setApprovalRules(this.approval_rules_list);
    },

    addNewCondition(newCondition) {
      this.approval_rules_list[this.index_approval].values.conditions.push(newCondition);
    },

    removeCondition(index) {
      this.rules_list.splice(index, 1);
    },

    changeTypeValue(event, index, typeSelected) {
      this.approval_rules_list[this.index_approval].values.conditions.splice(index, 1, {
        condition: "for",
        type: typeSelected,
        type_value: event
      });
      this.rules_list = this.approval_rules_list[this.index_approval].values.conditions;
      this.setApprovalRules(this.approval_rules_list);
    }
  },
  destroyed() {
    this.approval_rules_list = [];
    this.setApprovalRules(this.approval_rules_list);
  }
};
</script>
