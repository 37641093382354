<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <div
        class="action py-0"
        v-for="(approver, index_approver) in approvers"
        :key="index_approver"
      >
        <v-row>
          <v-col :md="approvers.length > 1 ? 11 : 12" cols="12" class="py-0">
            <v-autocomplete
              v-model="approver.id"
              label="Aprovadores"
              outlined
              append-icon
              dense
              :items="userList"
              item-text="nome"
              item-value="id"
              :rules="[v => !!v || 'Aprovador obrigatório']"
              placeholder="Selecione um aprovador"
              @change="setUser(index_approver)"
              class="mr-1"
              :loading="loadingApprover"
            ></v-autocomplete>
          </v-col>
          <v-col cols="11" class="py-0" v-show="false">
            <v-text-field
              outlined
              dense
              label="Whatsapp"
              :loading="loadingWhatsapp"
              v-model="approver.whatsapp"
              @change="updateNumber(index_approver)"
            ></v-text-field>
          </v-col>

          <v-col v-if="approvers.length > 1" cols="1" class="d-flex justify-end py-0 pa-6">
            <v-btn
              color="error"
              fab
              small
              dark
              title="Remover Aprovador"
              @click="removePhone(index_approver)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
            @click="addOption(index)"
            variant="outlined"
            elevation="5"
            small
            color="primary"
        >
          Adicionar Aprovador
          <v-icon right> mdi-plus-circle-outline </v-icon>
        </v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  //\s?(?:\()[0-9]{2}(?:\))\s
  props: ["index", "userList", "approvalCard"],
  data() {
    return {
      loadingApprover: true,
      loadingWhatsapp: true,
      //rulesWhatsapp: [v => /^[0-9]{2}?(?:\()[0-9]{2}(?:\))?[0-9]{4,5}(?:-)[0-9]{4}$/.test(v) || "Insira um valor numerico."],
      approvers: []
    };
  },
  async mounted() {
    if (this.solicitationTypeApprovalRules[this.index] && this.solicitationTypeApprovalRules[this.index].approvers.length > 0) {
      this.approvers = this.solicitationTypeApprovalRules[this.index].approvers;
    } else {
      this.addOption();
    }

    this.loadingApprover = false;
    this.loadingWhatsapp = false;
  },
  computed: {
    ...mapState("rules", ["rules"]),
    ...mapState("approvalRules", ["solicitationTypeApprovalRules"])
  },

  methods: {
    ...mapMutations({ setApprovers: "approvalRules/setApprovers" }),

    addOption() {
      this.approvers.push({ id: "", whatsapp: ""});
    },

    setUser(index_approver) {
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id == this.approvers[index_approver].id) {
          this.approvers[index_approver].whatsapp = this.userList[i].linha;
        }
      }
      this.approvalCard[this.index].approvers = this.approvers;
    },

    updateNumber(index_approver) {
      this.approvalCard[this.index].approvers[
        index_approver
      ].whatsapp = this.approvers[index_approver].whatsapp;
    },

    removePhone(index_approver) {
      let newList = [];
      for (let i = 0; i < this.approvers.length; i++) {
        if (i != index_approver) {
          newList.push(this.approvers[i]);
        }
      }
      this.approvers = newList;
      this.approvalCard[this.index].approvers = this.approvers;
    }
  }
};
</script>
