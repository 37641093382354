<template>
  <v-row>
    <div class="pt-0 pb-1 col-6">
      <span class="subtitle-2 pr-2">Ações</span>
    </div>
    <v-col cols="12" class="py-0">
      <div v-for="(action, index_actions) in actions_card" :key="action.id">
        <v-sheet
          class="mx-auto mt-6"
          elevation="6"
        >
          <v-col cols="12">
            <v-row>
              <v-col cols="11">
                <actionType :index="index" :index_actions="index_actions" :rules_list="rules_list" :selectedId="selectedId" />
              </v-col>
              <v-col md="1" cols="1" class="d-flex justify-center align-center" v-if="actions_card.length > 1">
                <v-btn
                    color="error"
                    fab
                    small
                    dark
                    title="Remover ação"
                    @click="removeAction(index_actions)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-sheet>
      </div>
      <v-col cols="12" class="mt-2 d-flex justify-end">
        <v-btn
          @click="addAction(index)"
          variant="outlined"
          elevation="5"
          small
          color="primary"
        >
          Adicionar ação
          <v-icon right> mdi-plus-circle-outline </v-icon>
        </v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import actionType from "./actions/actionTypeComponent";

export default {
  components: {
    actionType
  },

  props: ["index", "rules_list", "selectedId"],

  data() {
    return {
      actions_card: [],
    };
  },

  async mounted() {
    this.rules_list = this.rules;
    this.actions_card = this.rules_list[this.index].actions;
  },

  computed: {
    ...mapState("solicitationType", ["new"]),
    ...mapState("rules", ["rules", "messageRules"])
  },

  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    addAction(index) {
      let uniq = "id" + new Date().getTime();
      this.rules_list[index].actions.push({ id: uniq, type: "", fields: {} });
      this.actions_card = this.rules_list[index].actions;

      this.setRules(this.rules_list);
    },

    removeAction(index_actions) {
      if (index_actions > -1) {
        this.rules_list[this.index].actions.splice(index_actions, 1);
      }
      this.actions_card = this.rules_list[this.index].actions;

      this.setRules(this.rules_list);
    }
  }
};
</script>
